<template>
  <div id="Home">
    <v-row no-gutters>
      <!-- <v-col lg="6" sm="12">
        <v-card flat tile color="transparent">
          <img class="bannerimage" src="../image/Desktop_Mortgage.png" />
        </v-card>
      </v-col> -->
      <!-- <v-col lg="12" sm="12">
        <v-card flat tile color="transparent"> -->
          <!-- <p class="headertext">Mortgage services</p> -->
          <p class="contenttext">
            Real estate transactions are complicated enough.
            We’re here to help.
          </p>
          <!-- <v-btn class="bannerbutton" rounded color="#21A049" dark small>
            <v-icon left dark> mdi-account </v-icon>
            Lorem Ipusum
          </v-btn>-->
        <!-- </v-card>
      </v-col> -->
      <!-- <v-col class="hidden-lg-and-up" lg="6" sm="12">
        <v-card flat tile color="transparent">
          <img class="bannerimage" src="../image/bannernew.svg" />
        </v-card>
      </v-col>-->
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Banner",
  data: () => ({
    alignments: ["start", "center", "end"]
  }),
  computed: {},
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#Home {
  padding: 4em 8em;
  margin-top: 64px;
  height: 30em;
    background-image: url("../image/Banner.png");
  /* background-repeat: repeat; */
  /* background-position: 25px 3px; */
  background-size: cover;
  transform: matrix(1, 0, 0, 1, 0, 0);
  /* background: #bebebe1d 0% 0% no-repeat padding-box !important; */
  opacity: 1;
  width: 100%;
}
.bannerimage {
  /* height: 400px; */
  max-width: 410px !important;
  float: left;
}

.bannerbutton {
  margin-top: 19px;
  position: absolute !important;
  left: 20px !important;
  text-align: left;
  font-weight: bold;
  font-size: 12px;
  font-family: "Mulish", sans-serif;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
}
.headertext {
  padding: 0px;
  text-align: left;
  padding-top: 20px;
  font-family: "Merriweather", sans-serif;
  font-size: 45px;
  font-weight: bold;
  letter-spacing: 0.32px;
  color: #1c1c1c;
  opacity: 1;
  padding-top: 20px;
  padding-bottom: 19px;
  padding-right: 100px;
}

.colorinherit {
  background-color: #bebebe1d;
}

.contenttext {
  padding: 100px 15px;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: bold;
  /* text-align: justify; */
  font-size: 22px;
  letter-spacing: 0.24px;
  color: white;
  opacity: 1;
  padding-right: 100px;
     display: none;
}



@media only screen and (max-width: 1280px) {
  #Home {
    height: 18em;
    padding: 1em;
    margin-top: 60px;
    background-image: url("../image/mobile_banner.png");
    background-size: cover;
  }

  .col {
    /* padding-top: 20px !important; */
  }

  .headertext,
  .contenttext {
    display: block;
    padding-right: 10px;
  }

  .bannerimage {
    max-width: 360px !important;
    /* float: left; */
  }

  .bannerbutton {
    position: unset !important;
    width: 80%;
  }
}
</style>
