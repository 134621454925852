<template>
  <div class="sectioncontent">
 
    <p class="contenthead">Your Convenience In Mind</p>

    
    <v-row class="hidden-md-and-down"
      ><v-col>
        <v-card outlined color="transparent" max-width="374">
          <img
            class="testiimagelogo"
            src="../image/cert_image.png"
          /> </v-card></v-col
      ><v-col>
        <v-card outlined color="transparent" max-width="374">
          <img
            class="testiimagelogo"
            src="../image/america_image.png"
          /> </v-card></v-col
      ><v-col>
        <v-card outlined color="transparent" max-width="374">
          <img
            class="testiimagelogo"
            src="../image/local_image.png"
          /> </v-card></v-col
      ></v-row>

  <div class=" align-center hidden-lg-and-up">
     <v-carousel
 
    >
      <v-carousel-item>
        <v-card class="contentcenter" outlined color="transparent" max-width="374">
          <img class="testiimagelogo" src="../image/cert_image.png" /> </v-card
      >
       </v-carousel-item>
      <v-carousel-item class="hidden-lg-and-up">
        <v-card class="contentcenter" outlined color="transparent" max-width="374">
          <img
            class="testiimagelogo"
            src="../image/america_image.png"
          /> </v-card>
           </v-carousel-item>
      <v-carousel-item class="hidden-lg-and-up">
        <v-card class="contentcenter" outlined color="transparent" max-width="374">
          <img
            class="testiimagelogo"
            src="../image/local_image.png"
          /> </v-card> </v-carousel-item>
     
      </v-carousel></div>
  </div>
</template>

<script>
export default {
  name: "Design7",
  props: {},
  data() {
    return {};
  },
  methods: {
    returntocontact() {
      this.$store.dispatch("changeHeader", "Contactus");
      return this.$router.push(`./contactus`);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-card.on-hover.theme--dark {
  background-color: rgba(#fff, 0.8);
  opacity: 0.38;
}

.v-card.on-hover.theme--dark.v-card__text {
  color: #000;
}

.sectioncontent {
  background-color: #c8cac8;
  text-align: left;
  /* margin-top: 25px;
  margin-bottom: 25px; */
  padding: 0px 8em 30px;
}
.contentcenter{
  margin:auto;
}
.cardtextbutton {
  font-size: 0.7em !important;
  font-weight: bold;
  margin-top: 15px;
  background: #fa7e5c 0% 0% no-repeat padding-box !important;
  border-radius: 34px;
  opacity: 1;
  margin-left: 5px;
}

.align-center{
  margin:auto
}


.headercontent {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
  letter-spacing: 0px;
  color: #147587;
  opacity: 1;
  padding-top: 20px;
}

.imagelogo {
  height: 35px;
  width: 35px;
}
.testiimagelogo {
  /* height: 25%; */
  width: 100%;
}
.smallbutton {
  display: none;
  padding: 0px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
  position: absolute;
  bottom: 15px;
}

.alignleft {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: left;
  padding: 20px;
  height: auto;
  max-height: 270px;
  width: 330px;
  margin: auto;
  border: 1px solid #dddddd;
  border-radius: 10px;
  margin-bottom: 36px;
}

.imagewidth {
  width: 706px;
  height: 406px;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.imageleft {
  margin: auto;
}

.contenthead {
  text-align: left;
  font-size: 28px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: bold;
  letter-spacing: 0px;
  color: #184825;
  opacity: 1;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-top: 30px;
}

.contentdesc {
  text-align: justify;
  margin-bottom: 30px;
  font-size: 16px;
  font-family: "Nunito", sans-serif;
  letter-spacing: 0.24px;
  color: #151515;
}

.contentdesc_block {
  text-align: left;
  margin-top: 10px;
  font-size: 16px;
  font-family: "Nunito", sans-serif;
  letter-spacing: 0.24px;
  color: #151515;
}

.contentlist {
  padding-top: 14px;
  margin-left: 10px;
  text-align: left;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  color: #151515;
  opacity: 1;
}

ul {
  list-style-type: none;
}

ul li:before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  background-image: url("../image/tickbullet.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: -35px;
}

li {
  padding-bottom: 10px;
}

li b {
  position: relative;
  left: 10px;
}

@media only screen and (max-width: 960px) {
  .sectioncontent {
    padding: 15px 30px;
  }

  .cardtextbutton {
    width: 100%;
  }

  .alignleft {
    height: auto;
    max-height: 280px;
  }
  .contenthead{
    text-align: center;
  }
}

@media only screen and (max-width: 900px),
  (height: 1024px) and (width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape),
  (width: 1024px) and (height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
}
</style>
