<template>
  <div>
    <!-- <Header /> -->
    <Design9 />
    <!-- <Design10 /> -->
  </div>
</template>

<script>
import Design9 from "../components/Design9.vue";
// import Design10 from "../components/Design10.vue";
// import Header from "../components/Header.vue";
export default {
  name: "Home",

  components: {
    // Header,
    Design9,
    // Design10,
  },
  mounted() {
    // this.$store.dispatch("changeHeader", "Cccontactus");
  },
};
</script>

<style></style>
