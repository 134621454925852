<template>
  <div id="ContactUs">
    <!-- <div class="hidden-lg-and-up text-left contactinfo">
      <v-row
        ><v-col>
          <v-icon> mdi-email </v-icon>
          <a
            href="mailto:contact@zntcorp.com?subject=Enquiry&body=Hello!"
            class="countrysmalltext"
            >contact@zntcorp.com</a
          ></v-col
        ></v-row
      >
      <v-row
        ><v-col>
          <v-icon> mdi-phone </v-icon>
          <span class="countrysmalltext">080-49759999</span></v-col
        ></v-row
      >
    </div>-->
    <v-row>
      <v-col lg="6" sm="12" class="hidden-md-and-down">
        <img class="imagewidth" src="../image/contact.png" />
      </v-col>
      <v-col lg="6" sm="12" class="contentinfo">
        <p class="titlevalue">CONTACT US</p>
        <p class="titlevaluedesc">
          To learn more, or for a quick consultation,
           let us know a few of your details. We’ll reach out to you as soon as possible.
        </p>

        <img class="imagewidth hidden-lg-and-up" src="../image/contact.png" />

        <v-form ref="form" @submit.prevent="submitContact">
          <v-text-field
            v-model="name"
            background-color="#EDF7FF"
            id="textfield"
            placeholder="Name"
            solo
            :rules="nameRules"
            label="Name"
            required
            @input="changefield"
          ></v-text-field>

          <v-text-field
            v-model="Company"
            background-color="#EDF7FF"
            id="textfield"
            placeholder="Company"
  
            solo
            label="Company"
            required
            @input="changefield"
          ></v-text-field>

        <span >Business Type</span>
<span class="hidden-md-and-up"> 
<v-select multiple v-model="BusinessType" :options="typeoptions"  :reduce="(option) => option.id"/>
</span>
          <v-row class="hidden-sm-and-down">
          <v-col
          class="paddingblock"
            cols="12"
            sm="4"
            md="4"
          >
            <v-checkbox
              v-model="BusinessType"
              label="Title Agent"
              value="1"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="BusinessType"
              label="Title Underwriter"
              value="4"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col
          class="paddingblock"
            cols="12"
            sm="4"
            md="4"
          >
            <v-checkbox
              v-model="BusinessType"
              label="Escrow Agent"
              value="2"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="BusinessType"
              label="Lender"
              value="5"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col
          class="paddingblock"
            cols="12"
            sm="4"
            md="4"
          >
            <v-checkbox
              v-model="BusinessType"
              label="Attorney"
              value="3"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="BusinessType"
              label="Other"
              value="6"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
        <br>
      
          <v-text-field
            background-color="#EDF7FF"
            v-model="email"
            solo
            :rules="emailRules"
            label="E-mail"
            required
            @input="changefield"
          ></v-text-field>

          <v-text-field
            background-color="#EDF7FF"
            v-model="Phone"
            solo
            :rules="phoneRules"
            label="Phone"
            required
            @input="changefield"
          ></v-text-field>


          <v-alert v-if="successVal" outlined type="success" text>
            Thank you for contacting us. We will review your request and get
            back to you at the earliest.
          </v-alert>
          <v-btn
            :disabled="!valid"
            color="#fa7e5c"
            class="cardtextbutton mr-4"
            rounded
            type="submit"
          >Submit</v-btn>
        </v-form>
      </v-col>
    </v-row>



  </div>
</template>

<script>
// import emailjs from "emailjs-com";
import 'vue-select/dist/vue-select.css';
const axios = require("axios");
export default {
  name: "Design9",
  props: {},
  data: () => {
    return {
      selected:[],
      typeoptions:[{label: 'Title Agent', id: 1},{label: 'Escrow Agent', id: 2},{label: 'Attorney', id: 3},{label: 'Title Underwriter', id: 4},{label: 'Attorney', id: 5},{label: 'Others', id: 6}],
       successVal: false,
 valid: true,
      name: "",
      Company:"",
      nameRules: [v => !!v || "Name is required"],
      // companyRules: [v => !!v || "Company is required"],
      BusinessType: [],
      email: "",
      emailRules: [
        v => !!v || "E-mail is required",
        v =>
          v.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || "E-mail must be valid"
      ],
      Phone:"",
      phoneRules:[v => Number.isInteger(Number(v)) || "The value must be an integer number",
      v => v.length == 10 || "The value must be equal to 10 digits"],
      comment: "",
      commentRules: [v => !!v || "Comment is required"]
    };
  },
  watch: {},
  mounted() {},
  methods: {
    changefield() {
      this.successVal = false;
    },
    openGoogleMap(item) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${item}`,
        "_blank"
      );
    },
    openGoogletinyusaMap() {
      window.open(`https://goo.gl/maps/mcn3skRhuChH5LTT7`, "_blank");
    },
    openGoogletinydubaiMap() {
      window.open(`https://goo.gl/maps/ZM3UqKfoG9wJJxAQ9`, "_blank");
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    submitContact() {
      console.log("Post");
      const datav = {};
      datav.name = this.name;
      datav.email = this.email;
      datav.company = this.Company;
           datav.business_type_id = [1, 2];
            datav.phone = this.Phone;
      console.log(datav);

      // emailjs
      //   .send(
      //     "service_unrcd8a",
      //     "template_x114bcv",
      //     { name: this.name, company: this.Company, businesstype: this.BusinessType, email:this.email, phone:this.Phone  },
      //     "user_JAriCGaLhJ5Tts2LqutKQ"
      //   )
      //   .then(
      //     result => {
      //       console.log("SUCCESS!", result.text);
      //     },
      //     error => {
      //       console.log("FAILED...", error.text);
      //     }
      //   );

      const headers = {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json"
      };
   this.successVal = true;
   this.name = "";
          this.email = "";
          this.Company = "";
             this.BusinessType = [];
                this.Phone = "";
          this.resetValidation();
      axios
        // .post(
        //   `http://54.212.154.15:8044/api/zenith-contact-us?name=${this.name}&email=${this.email}&business_type_id=${this.BusinessType}&phone=${this.Phone}&company=${this.Company}`,
        //   { headers }
        // )
          .post(
          `http://54.212.154.15:8044/api/zenith-contact-us`,
          datav, 
          { headers }
        )
        .then(() => {
       
          this.name = "";
          this.email = "";
          this.Company = "";
             this.BusinessType = [];
                this.Phone = "";
          this.resetValidation();
        })
        .catch(error => {
          console.log("Error ========>", error);
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


#ContactUs {
  background-color: white;
  padding: 80px 8em 40px 2em;
  text-align: left;
}
.contentinfo {
  padding: 0px 70px;
}
.imagewidth {
  padding-top: 100px;
  padding-left:100px;
  width:100%;
  /* height: 620px;
  width: 620px; */
}

.gimagewidth {
  height: 500px;
  width: 100%;
}

.mobview {
  text-align: left;
}

.countrytext {
  text-align: left;
  font-family: "Nunito", sans-serif;
  font-size: 15px;
  font-weight: 800;
  color: #151515;
  opacity: 1;
  padding-right: 40px;
}

.Addresstext {
  font-size: 12px;
}

.contactinfo {
  padding-top: 50px;
  padding-left: 10px;
  padding-bottom: 50px;
}

.countrysmalltext {
  padding: 0px 50px 0px 5px;
  text-align: center;
  font-family: "Nunito", sans-serif;
  font-size: 13px;
  font-weight: 800;
  color: #151515;
  opacity: 1;
}

.formfield {
  width: 80%;
  margin: auto;
}
#textfield {
  border: none;
}
.titlevalue {
  text-align: center;
  font-family: "Nunito", sans-serif;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0.45px;
  color: #151515;
  opacity: 1;
}

.cardtextbutton {
  width: 120px;
  font-size: 0.9em !important;
  font-weight: bold;
  margin-top: 15px;
  background: #184825 0% 0% no-repeat padding-box !important;
  border-radius: 34px;
  opacity: 1;
  margin-left: 5px;
  color: #fff;
}

.v-label {
  color: #4c7595 !important;
}

.centerbutton {
  text-align: center;
}

.titlevaluedesc {
  text-align: center;
  /* font: normal normal normal 22px/30px Nunito; */
  font-size: 18px;
  font-family: "Nunito", sans-serif;
  letter-spacing: 0.33px;
  color: #151515;
  opacity: 1;
}

.v-input__slot {
  border-color: null;
}

.v-text-field.v-label {
  color: #4c7595 !important;
}
  .paddingblock{
    padding: 0px 12px !important;
    padding-right: 0px !important;
  }

@media only screen and (max-width: 960px) {

  .paddingblock{
    padding: 0px 12px !important;
  }
  #ContactUs {
    padding: 75px 30px;
  }

  .cardtextbutton {
    width: 100%;
  }

  .imagewidth {
    width: -webkit-fill-available;
    height: unset;
    padding-bottom: 20px;
    padding-top:0px;
    padding-left:0px;
  }

  .contentinfo {
    padding: 0px 20px;
  }
}
</style>
