import Vue from "vue";
import Router from "vue-router";
import Home from "./view/Home";
// import Landing from "./view/Landing";
import Terms from "./view/Terms";
import Meta from "vue-meta";
Vue.use(Router);
Vue.use(Meta);

export default new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash };
    }
    return { x: 0, y: 0 };
  },
  base: "/",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/terms",
      name: "Terms",
      component: Terms,
    },
  ],
});
