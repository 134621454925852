<template>
  <div class="Servicemenu">
    <vue-file-toolbar-menu :content="my_menu" class="topmenu" />
  </div>
</template>

<script>
import VueFileToolbarMenu from "vue-file-toolbar-menu";
import { mapState } from "vuex";
export default {
  components: { VueFileToolbarMenu },

  data() {
    return { happy: false };
  },
  methods: {
    changeheader(item) {
      this.$store.dispatch("changeHeader", item);
    },
  },
  computed: {
    ...mapState({
      selectedheader: (state) => state.headerbutton,
    }),
    my_menu() {
      return [
        {
          text: "OUR SERVICES",
          active: this.selectedheader == "OurServices" ? true : false,
          menu_width: 200,
          chevron: true,
          menu: [
            {
              text: "Title Production",
              click: () => {
                this.$store.dispatch("changeHeader", "OurServices");
                var element = document.getElementById("OurServices");
                element.scrollIntoView({behavior: "smooth"});
              },
            },

            {
              text: "Escrow Production",
                click: () => {
                this.$store.dispatch("changeHeader", "OurServices");
                var element = document.getElementById("OurServices");
                element.scrollIntoView({behavior: "smooth"});
              },
            },
            {
              text: "Data Solutions",
              click: () => {
                this.$store.dispatch("changeHeader", "OurServices");
                var element = document.getElementById("OurServices");
                element.scrollIntoView({behavior: "smooth"});
              },
            },
            {
              text: "Mortgage Services",
              click: () => {
                this.$store.dispatch("changeHeader", "OurServices");
                var element = document.getElementById("OurServices");
                element.scrollIntoView({behavior: "smooth"});
              },
            },
            {
              text: "Business Operations",
              click: () => {
                this.$store.dispatch("changeHeader", "OurServices");
                var element = document.getElementById("OurServices");
                element.scrollIntoView({behavior: "smooth"});
              },
            },
          ],
        },
      ];
    },
  },
};
</script>


<style lang="scss" scoped>
.topmenu {
  height: 60px !important;
  justify-content: center;
}

.bar .bar-button.active {
  color: #fff;
  background-color: #184825;
}
</style>