<template>
  <div>
    <Headernew />
    <Privacy />
    <Design10 />
  </div>
</template>

<script>
import Design10 from "../components/Design10.vue";
import Privacy from "../components/Privacy.vue";
import Headernew from "../components/Headernew.vue";
export default {
  name: "Title",
  data() {
    return {
      header1: "",
      desc1: "",
      button1: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  components: {
    Headernew,
    Design10,
    Privacy,
  },
};
</script>

<style>
@media only screen and (max-width: 960px) {
  .v-window__next,
  .v-window__prev {
    top: calc(70% - 20px);
  }
}
</style>
