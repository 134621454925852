<template>
  <div>
    <v-btn
      :key="label.text"
      color="#36393E"
      text
      class="headerbutton d-flex flex-row-reverse"
      :class="selectedheader === label.value ? 'active_header' : ''"
      @click="changeheader(label.value)"
    >
      {{ label.text }}
    </v-btn>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Headerbutton",
  props: {
    label: {
      type: Object,
      default: null,
    },
    flags: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      selectedheader: (state) => state.headerbutton,
    }),
  },
  methods: {
    changeheader(item) {
      // if (item === "Home") {
      //   this.$router.push(`./`);
      // } else {
      //   this.$router.push(`./${item}`);
      // }
console.log(item)
if(item === "ContactUs" || item === "FAQ"|| item === "OurServices" || item === "TheOctantPlatform" )
{
  console.log(item)
 var element = document.getElementById(item);
      element.scrollIntoView({behavior: "smooth"});
}
     
      this.$store.dispatch("changeHeader", item);
    },
  },
};
</script>

<style scoped>
.headerbutton {
  font-family: "Poppins", sans-serif;
  font-size: 16px !important;
  /* padding-right: 15px; */
  padding-left: 15px;
  height: 60px !important;
  background-color: unset;
  text-align: left;
  letter-spacing: 0px;
  color: #151515;
  opacity: 1;
}

.headerbutton_changecolor {
  background-color: grey;
  color: white;
}

.headerbutton_changecolor:hover {
  background-color: #184825 !important;
  color: white;
}

.headerbutton:hover {
  color: #ffff !important;
  font-family: "Poppins", sans-serif;
  background: #184825 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 5px 5px;
  opacity: 1;
}

.active_header {
  color: #ffff !important;
  background-color: #184825 !important;
}
</style>
