<template>
  <div id="footer">
    <div>
      <cookie-law theme="blood-orange--rounded"> </cookie-law>
      <v-row>
        <img
          @click="routeHome"
          class="footerimage"
          :src="require('../image/footerwhite.webp')"
        />
      </v-row>
      <v-row class="footer hidden-md-and-down">
        <v-col lg="4" sm="4" md="4">
          <h3 class="footerheader">Our Services</h3>
          <ul class="listitem">
            <li @click="redirecttoservice">Title Production</li>
            <li @click="redirecttoservice">Escrow Production</li>
            <li @click="redirecttoservice">Data Solutions</li>
            <li @click="redirecttoservice">Mortgage Services</li>
            <li @click="redirecttoservice">Business Operations</li>
            <!-- <li @click="redirecttoservice">Nationwide Title Searches</li>
            <li @click="redirecttoservice">Nationwide Tax Solutions</li>
            <li @click="redirecttoservice">PostClose & Recording Services</li>
            <li @click="redirecttoservice">Easement Plotting</li> -->
    
            <!-- <li @click="redirecttomort">Mortgage Services</li>
            <li @click="redirecttotitleset">Title & Settlement Services</li>
            <li @click="redirecttorereal">Real Estate Tax Solutions</li>
            <li @click="redirecttotitleplant">Title Plant Indexing</li>
            <li @click="redirecttoappraisal">Appraisal Services</li> -->
          </ul>
        </v-col>
        <!-- <v-col lg="2"  sm="4" md="4">
          <h3 class="footerheader">The Octant Platform</h3>
          <ul class="listitem">
            <li @click="redirecttouni">FastTaxCert</li> -->
            <!-- <li @click="redirecttouni">FastTax Monitoring</li>
                        <li >Coming soon..</li>
            <li >Coming soon..</li>
            <li @click="redirecttofast">Fast L & V</li> -->
<!-- 
            <li>Fast Tax monitoring</li>
          </ul> -->
        <!-- </v-col>  -->
        <v-col class="footercontent" lg="4" sm="12" md="12">
          <h3 class="footerheader">Why Zenith</h3>
          <p class="footerdetail">
           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
          </p>

          <!-- <span class=" hidden-md-and-down footerdetail">
            <img class="footericonimage" src="../image/menlogo.jpg" alt="" />
            <p class="iconheader"># Successful Startups</p>
            <p class="iconcontent">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour,
            </p>
          </span> -->
        </v-col>
        
        
        <v-col lg="4" sm="4" md="4">
          <div style="float:right">
      
          <p @click="redirecttoabout" class="footerinfoabout">About us</p>
          <p class="footerinfo">Follow us</p>
          <v-row>
            <v-btn
            @click="redirecttoinsta()"
              class="mx-2 footerbutton"
              fab
              dark
              x-small
              color="#21A049"
            >
              <v-icon dark> mdi-instagram </v-icon>
            </v-btn>
            <v-btn
        @click="redirecttotwitter()"
              class="mx-2 footerbutton"
              fab
              dark
              x-small
              color="#21A049"
            >
              <v-icon dark> mdi-twitter </v-icon>
            </v-btn>
            <v-btn
          @click ="redirecttofb()"
              class="mx-2 footerbutton"
              fab
              dark
              x-small
              color="#21A049"
            >
              <v-icon dark> mdi-facebook </v-icon>
            </v-btn>
            <v-btn
         
              class="mx-2 footerbutton"
              fab
              dark
              x-small
             @click ="redirecttoli()"
              color="#21A049"
            >
              <v-icon dark > mdi-linkedin </v-icon>
            </v-btn>
          </v-row>
          </div>
        </v-col>
      </v-row>

      <v-row class="footer hidden-lg-and-up">
        <v-col class="footercontent" lg="4" sm="12" md="12">
          <h3 class="footerheader">Why Zenith</h3>
          <p class="footerdetail">
           We are a technology-focused company, however we firmly believe not every problem in our industry is solved with process automation. At Zenith, we methodically infuse industry knowledge and procedural diligence when creating tailored solutions. At Zenith, we’re here to make your life easier.
          </p>

          <!-- <span class=" hidden-md-and-down footerdetail">
            <img class="footericonimage" src="../image/menlogo.jpg" alt="" />
            <p class="iconheader"># Successful Startups</p>
            <p class="iconcontent">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour,
            </p>
          </span> -->
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col lg="3" sm="4" md="4">
          <h3 class="footerheader hidden-lg-and-up">The Octant Platform</h3>
          <ul class="listitem hidden-lg-and-up">
            <li >Coming soon ..</li>
            <li >Coming soon ..</li>
            
          </ul>
        </v-col> -->
        <v-col lg="4" sm="4" md="4">
          <h3 class="footerheader hidden-lg-and-up">Our Services</h3>
          <ul class="listitem hidden-lg-and-up">
            <li @click="redirecttoservice">Title Production</li>
            <li @click="redirecttoservice">Escrow Production</li>
            <li @click="redirecttoservice">Data Solutions</li>
            <li @click="redirecttoservice">Mortgage Services</li>
            <li @click="redirecttoservice">Business Operations</li>
         
          </ul>
        </v-col>
        <v-col lg="4" sm="4" md="4">
          <p @click="redirecttoabout()" class="footerinfo hidden-lg-and-up">About us</p>
          <p class="footerinfo hidden-lg-and-up">Follow us</p>
          <v-row class="footerinfo hidden-lg-and-up">
            <v-btn
              @click="redirecttoinsta()"
              class="mx-2 footerbutton"
              fab
              dark
              x-small
              color="#21A049"
            >
              <v-icon dark> mdi-instagram </v-icon>
            </v-btn>
            <v-btn
              @click="redirecttotwitter()"
              class="mx-2 footerbutton"
              fab
              dark
              x-small
              color="#21A049"
            >
              <v-icon dark> mdi-twitter </v-icon>
            </v-btn>
            <v-btn
              @click="redirecttofb()"
              class="mx-2 footerbutton"
              fab
              dark
              x-small
              color="#21A049"
            >
              <v-icon dark> mdi-facebook </v-icon>
            </v-btn>
            <v-btn
              @click="redirecttoli()"
              class="mx-2 footerbutton"
              fab
              dark
              x-small
              color="#21A049"
            >
              <v-icon dark> mdi-linkedin </v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-row align="" style="margin-bottom: 0px">
        <span class="footervalue" @click="redirecttoprivacy">
          Terms and Conditions</span
        >
        <span class="footervalue" @click="redirecttoprivacy"
          >Privacy Policy</span
        >
        <span class="footervalue" @click="redirecttoprivacy"
          >Cookie Policy</span
        >
        <span class="footervalue" @click="redirecttoprivacy">Copyright</span>
        <span class="footervalue" @click="redirecttoprivacy"
          >Policies and Procedures</span
        >
      </v-row>
      <v-divider color="white" style="margin-left: -12px"></v-divider>
      <v-row>
        <p class="copyright" style="margin-top: 15px">
          © 2022 ZenithCorp. All rights reserved.
        </p>
      </v-row>
    </div>
  </div>
</template>

<script>
import CookieLaw from "vue-cookie-law";
export default {
  name: "Footer",
  components: { CookieLaw },
  props: {},
  data() {
    return {
      links: ["Home", "About Us", "Team", "Services", "Blog", "Contact Us"],
    };
  },
  methods: {
    routeHome() {
      this.$store.dispatch("changeHeader", "Home");
      var element = document.getElementById("Home");

element.scrollIntoView({behavior: "smooth"});
    },
    redirecttoinsta() {
      window.open(``, "_blank");
    },
    redirecttotwitter() {
      window.open(`https://twitter.com/TitleZenith`, "_blank");
    },
    redirecttoabout() {
       var element = document.getElementById('AboutUs');

element.scrollIntoView({behavior: "smooth"});
      this.$store.dispatch("changeHeader", "About");
    },
    redirecttofb() {
      window.open(`https://www.facebook.com/Zenith-Conglomerate-Holdings-100827292660386`, "_blank");
    },
    redirecttoli() {
      window.open(`https://www.linkedin.com/in/zenith-title-b3a782240/`, "_blank");
    },


    redirecttouni() {
        var element = document.getElementById("Products");
      element.scrollIntoView({behavior: "smooth"});
this.$store.dispatch("changeHeader", "Products");
    },
      redirecttoservice() {
        var element = document.getElementById("OurServices");
      element.scrollIntoView({behavior: "smooth"});
this.$store.dispatch("changeHeader", "Products");
    },
    redirecttoprivacy() {
      this.$store.dispatch("changeHeader", "Home");
      window.scrollTo(0, 0);
      return this.$router.push(`./terms`);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#footer {
  /* margin-top: 100px; */
  color: white;
  /* height: 450px; */
  width: auto;
  background: white 0% 0% no-repeat padding-box;

  opacity: 1;
  padding: 70px 8em 20px 8em;
}
.footerimage {
  margin-left: 10px;
  float: left;
  z-index: 0;
  position: absolute;
  width: 200px;
  /* background: transparent url('../image/logo.png') 0% 0% no-repeat padding-box; */
  opacity: 1;
}

.footerimage:hover {
  cursor: pointer;
}
.footerinfo {
  text-align: left;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.footerinfoabout {
  cursor: pointer;
  text-align: left;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.footericon {
  padding-top: 10px;
  padding-left: 10px;
  cursor: pointer;
}

.footerbutton {
  margin: 10px;
}

.footericon:hover {
  transition: transform 0.2s;
  width: 50px;
  height: 50px;
}
.footericonimage {
  border-radius: 20px;
  float: left;
  padding: 10px;
  z-index: 9999;
  width: 122px;
  height: 89px;
  /* background: transparent url('../image/logo.png') 0% 0% no-repeat padding-box; */
  opacity: 1;
}

.iconheader {
  text-align: left;
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.copyright {
  text-align: left;
  font-family: "Nunito", sans-serif;
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.iconcontent {
  text-align: left;
  font-family: "Nunito", sans-serif;
  font-size: 10px;
  letter-spacing: 0.15px;
  color: #ffffff;
  opacity: 1;
}
.icon {
  padding-right: 10px;
  padding-top: 10px;
}
li {
  text-align: left;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  letter-spacing: 0.19px;
  color: #ffffff;
  opacity: 0.79;
  padding-bottom: 5px;
}

.footervalue {
  font-size: 14px;
  opacity: 0.79;
  padding-right: 25px;
  font-family: "Nunito", sans-serif;
  padding-bottom: 10px;
  font-weight: 600;
}

.footervalue:hover {
  font-size: 16px;
  opacity: 1;
  color: #21A049;
  cursor: pointer;
}

.addressinfo {
  padding-top: 5px;
  text-align: left;
  font-family: "Nunito", sans-serif;
  font-size: 10px;
  letter-spacing: 0.24px;
  color: #ffffff;
  margin-bottom: 2px;
}
.address {
  padding-top: 20px;
  text-align: left;
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.footerheader {
  /* margin-top: 95px; */
  text-align: left;
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.footer {
  text-align: left;
  margin-top: 10px !important;
  padding-top: 70px;
}
.image {
  width: 100%;
  height: 0;
  border-top: 100px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 1200px solid blue;
}

.footercontent {
  /* margin-top:65px;? */
  /* padding: 0px 60px; */
}

.v-text-field {
  border-radius: 10px;
}

.listitem {
  text-align: left;
  list-style-type: none;
  padding-top: 12px;
  padding-left: 0px;
}

li:hover {
  font-size: 14px;
  color: #21A049;
  font-weight: bold;
  cursor: pointer;
}

.listitems {
  list-style-type: none;
  padding-top: 20px;
  padding-left: 0px;
}

.footerdetail {
  text-align: justify;
  padding-top: 18px;
  padding-right: 20px;
  text-align: left;
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  letter-spacing: 0.24px;
  color: #ffffff;
  opacity: 0.8;
}
@media only screen and (min-width: 960px) {
  #footer {
  /* background-image: url("../image/footer_bg.svg"); */
  background-size: 175%;
  background-color: #184825;
  }
}

  
@media only screen and (max-width: 960px) {
  #footer {
    padding: 30px 30px 20px 30px;
    /* background-image: url("../image/mobile_footer.webp"); */
    background-size: 170%;
    background-color: #184825;
  }

  .footervalue {
    font-size: 12px;
  }

  .footerimage {
    width: 40%;
  }
}
</style>
