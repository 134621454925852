<template>
  <div class="Aboutmenu">
    <vue-file-toolbar-menu :content="my_menu" class="topmenu" />
  </div>
</template>

<script>
import VueFileToolbarMenu from "vue-file-toolbar-menu";
import { mapState } from "vuex";
export default {
  components: { VueFileToolbarMenu },

  data() {
    return { happy: false };
  },
  methods: {
    changeheader(item) {
      this.$store.dispatch("changeHeader", item);
    },
  },
  computed: {
    ...mapState({
      selectedheader: (state) => state.headerbutton,
    }),
    my_menu() {
      return [
        {
          text: "ABOUT US",
          active: this.selectedheader == "AboutUs" ? true : false,
          menu_width: 200,
          chevron: true,
          menu: [
            {
              text: "Mission",
              click: () => {
                this.$store.dispatch("changeHeader", "AboutUs");
                  var element = document.getElementById("AboutUs");
                element.scrollIntoView({behavior: "smooth"});
              },
            },

            {
              text: "History",
                click: () => {
                this.$store.dispatch("changeHeader", "AboutUs");
                   var element = document.getElementById("AboutUs");
                element.scrollIntoView({behavior: "smooth"});
              },
            },
            {
              text: "Audience",
              click: () => {
                this.$store.dispatch("changeHeader", "AboutUs");
                   var element = document.getElementById("AboutUs");
                element.scrollIntoView({behavior: "smooth"});
              },
            },
          ],
        },
      ];
    },
  },
};
</script>


<style lang="scss" scoped>
.topmenu {
  height: 60px !important;
  justify-content: center;
}

.bar .bar-button.active {
  color: #fff;
  background-color: #184825 !important;
}

</style>