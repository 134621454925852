<template>
  <div id="OursServices" class="design4">
    <div class="roundcontent">
      <div class="roundcontenttext">
        <div class="titlecontent">
          <div class="sectiontitle">Zenith Services and Offerings</div>
          <div class="sectionbody">
            As a rapidly expanding technology-focused service provider, we have
            both out of the box and specifically tailored solutions for your
            business. Here are just a few example of what we offer:
          </div>
        </div>

        <v-row class="contentpadding hidden-md-and-up">
          <v-col sm="12" md="6">
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/Mortgage Service.png" />
                <div class="cardtitle">Comprehensive Title Searches</div>
                <p class="cardtitletext">
                  We have national coverage through our online and on-the-ground
                  vendor network, and our team of examiners have a thorough
                  understanding of all regional and local state statutes &
                  guidelines to deliver best in class verified searches.
                  Products are optimized for any underwriter, state, and product
                  type for the most seamless product to our clients.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="contentpadding hidden-md-and-up">
          <v-col sm="12" md="6">
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img
                  class="LogoAnimation"
                  src="../image/Nationwide Tax Solutions.png"
                />
                <div class="cardtitle">Tailored Settlement Support</div>
                <p class="cardtitletext">
                  We customize our settlement functions to each client,
                  performing tasks as simple as a payoff request to more complex
                  items like dissecting, uploading, and completing data entry on
                  full closing packages. Our industry professionals ensure a
                  smooth onboarding, and are sensitive to the high expectations
                  of our closing professionals in every region.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="contentpadding hidden-md-and-up">
          <v-col sm="12" md="6">
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img
                  class="LogoAnimation"
                  src="../image/customized.png"
                />
                <div class="cardtitle">National Data Solutions</div>
                <p class="cardtitletext">
                  Having decades of scanning, indexing, and mapping experience,
                  we have handled millions of assets with strategic precision.
                  Whether you are looking for plant creation, plant management,
                  or a localized data project, Zenith has ready and scalable
                  solutions.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="contentpadding hidden-md-and-up">
          <v-col sm="12" md="6">
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img
                  class="LogoAnimation"
                  src="../image/PostCloseRecordingServices.png"
                />
                <div class="cardtitle">Post-Closing & E-Recording Services</div>
                <p class="cardtitletext">
                  We intimately understand the urgency and accuracy required for
                  all closing functions , which is why we at Zenith deploy a
                  multiple verification process that ensures accuracy without
                  sacrificing speed. Our 24/7 coverage means that even during
                  the busiest workdays, our team is working hard behind the
                  scenes to impress your clients, and help you scale.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="contentpadding hidden-md-and-up">
          <v-col sm="12" md="6">
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img
                  class="LogoAnimation"
                  src="../image/Easement Plotting.png"
                />
                <div class="cardtitle">Easement Plotting</div>
                <p class="cardtitletext">
                  Our team of experts understand the criticality and the
                  importance of being accurate on highlighting the exceptions
                  and easements for the property in question. Our team experts
                  are well versed with state requirements.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="contentpadding hidden-md-and-up">
          <v-col sm="12" md="6">
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img
                  class="LogoAnimation"
                  src="../image/Nationwide Tax.png"
                />
                <div class="cardtitle">Nationwide Tax Solutions</div>
                <p class="cardtitletext">
                  One of our premier technology solutions, our tax products
                  incorporate automation and intuitive integrations that allow
                  us to obtain nationwide tax information faster, many times
                  immediately. Additionally, our system produces Certificates
                  customized to any and all of your organization’s
                  requirements.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <div class="contentpadding hidden-sm-and-down">
          <v-card
            class="cardcontent"
            flat
            max-width="21.25em"
            min-width="300px"
          >
            <v-card-text class="iconview">
              <!-- <v-icon large color="teal darken-2">
                  mdi-email
                </v-icon> -->
              <img class="LogoAnimation" src="../image/Mortgage Service.png" />
              <div class="cardtitle">Comprehensive Title Searches</div>
              <p class="cardtitletext">
                We have national coverage through our online and on-the-ground
                vendor network, and our team of examiners have a thorough
                understanding of all regional and local state statutes &
                guidelines to deliver best in class verified searches. Products
                are optimized for any underwriter, state, and product type for
                the most seamless product to our clients.
              </p>
            </v-card-text>
          </v-card>

          <v-card
            class="cardcontent"
            flat
            max-width="21.25em"
            min-width="300px"
          >
            <v-card-text class="iconview">
              <img class="LogoAnimation" src="../image/Nationwide Tax Solutions.png" />
              <div class="cardtitle">Tailored Settlement Support</div>
              <p class="cardtitletext">
                We customize our settlement functions to each client, performing
                tasks as simple as a payoff request to more complex items like
                dissecting, uploading, and completing data entry on full closing
                packages. Our industry professionals ensure a smooth onboarding,
                and are sensitive to the high expectations of our closing
                professionals in every region.
              </p>
            </v-card-text>
          </v-card>

          <v-card
            class="cardcontent"
            flat
            max-width="21.25em"
            min-width="300px"
          >
            <v-card-text class="iconview">
              <img
                class="LogoAnimation"
                src="../image/customized.png"
              />
              <div class="cardtitle">National Data Solutions</div>
              <p class="cardtitletext">
                Having decades of scanning, indexing, and mapping experience, we
                have handled millions of assets with strategic precision.
                Whether you are looking for plant creation, plant management, or
                a localized data project, Zenith has ready and scalable
                solutions.
              </p>
            </v-card-text>
          </v-card>
        </div>
        <div class="contentpadding hidden-sm-and-down">
          <v-card
            class="cardcontent"
            flat
            max-width="21.25em"
            min-width="300px"
          >
            <v-card-text class="iconview">
              <img
                class="LogoAnimation"
                src="../image/PostCloseRecordingServices.png"
              />
              <div class="cardtitle">Post-Closing & E-Recording Services</div>
              <p class="cardtitletext">
                We intimately understand the urgency and accuracy required for
                all closing functions , which is why we at Zenith deploy a
                multiple verification process that ensures accuracy without
                sacrificing speed. Our 24/7 coverage means that even during the
                busiest workdays, our team is working hard behind the scenes to
                impress your clients, and help you scale.
              </p>
            </v-card-text>
          </v-card>
          <v-card
            class="cardcontent"
            flat
            max-width="21.25em"
            min-width="300px"
          >
            <v-card-text class="iconview">
              <img class="LogoAnimation" src="../image/Easement Plotting.png" />
              <div class="cardtitle">Easement Plotting</div>
              <p class="cardtitletext">
                Our team of experts understand the criticality and the
                importance of being accurate on highlighting the exceptions and
                easements for the property in question. Our team experts are
                well versed with state requirements.
              </p>
            </v-card-text>
          </v-card>
          <v-card
            class="cardcontent"
            flat
            max-width="21.25em"
            min-width="300px"
          >
            <v-card-text class="iconview">
              <img
                class="LogoAnimation"
                src="../image/Nationwide Tax.png"
              />
              <div class="cardtitle">Nationwide Tax Solutions</div>
              <p class="cardtitletext">
                One of our premier technology solutions, our tax products
                incorporate automation and intuitive integrations that allow us
                to obtain nationwide tax information faster, many times
                immediately. Additionally, our system produces Certificates
                customized to any and all of your organization’s requirements.
              </p>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Design4",
  props: {},
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.design4 {
  background-image: url(../image/circle.svg);
  padding: 40px 8em 70px;
  background-color: white;
  background-position: bottom;
  background-size: 100% 900px;
  /* padding-bottom: 8em; */
}

.iconview {
  text-align: left;

  /* box-shadow: 0 0 3px 2px #fff; */
}

.iconview .v-icon {
  font-size: 55px !important;
}
.titlecontent {
  width: 70%;
  margin: auto;
  padding-bottom: 20px;
}

.cardcontent {
  /* margin: auto; */
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0px;
  padding-right: 15px;
}
.roundcontent {
  /* margin-top: -120px auto 0px auto; */
  /* margin: auto; */

  /* background: transparent
    radial-gradient(
      closest-side at 50% 42%,
      #4a7e88 0%,
      #78b2bed1 0%,
      #3a565b00 100%
    )
    0% 300% no-repeat padding-box; */
}

.LogoAnimation {
  width: 55px;
  /* transform: rotateY(560deg);
  animation: turn 3.5s ease-out forwards 1s; */
}

@keyframes turn {
  100% {
    transform: rotateY(0deg);
  }
}

.contentpadding {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
}
.roundcontenttext {
  margin: auto;
  text-align: center;
  opacity: 1;
}

.sectiontitle {
  padding-top: 30px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  font-family: "Nunito", sans-serif;
  letter-spacing: 0.42px;
  color: #151515;
  opacity: 1;
}

.sectionbody {
  padding-top: 15px;
  padding: 0px 15px;
  text-align: center;
  font-size: 16px;
  font-family: "Nunito", sans-serif;
  letter-spacing: 0.24px;
  color: #151515;
  /* opacity: 0.5; */
}

.cardtitle {
  padding-top: 20px;
  text-align: left;
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}

.cardtitletext {
  padding-top: 12px;
  text-align: justify;
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
  /* padding-right: 25px; */
}

.v-card__text {
  padding: 0px !important;
}
@media only screen and (max-width: 960px) {
  .design4 {
    padding: 20px 20px;
    background-position: top 50% left 50%;
    background-image: none;
  }

  .titlecontent {
    width: 100%;
  }
  .iconview,
  .cardtitle {
    text-align: center;
  }
  .cardcontent {
    max-width: 100% !important;
  }

  .contentpadding {
    padding-top: 0px;
  }
}
</style>
