<template>
  <div>
    <Header />
    <Banner/>

  <!-- <v-carousel
      hide-delimiter-background
      cycle
      :show-arrows="false"
      height="auto"
      interval="6000"
      class="bannercarousal"
    >
      <v-carousel-item>
        <Banner
          block="righttransblockwhite"
          headert="headertranstextright"
          content="contenttranstextright"
          mobimageName="Homemobile.png"
        />
      </v-carousel-item>
      <v-carousel-item>
         <Banner1
          block="righttransblockwhite"
          headert="headertranstextright"
          content="contenttranstextright"
          mobimageName="Homemobile.png"
        />
      </v-carousel-item>
      
    </v-carousel>  -->

    <!-- <Design12 /> -->
    <!-- <Design1
      title1="Digital Technology Services "
      desc="Our digital technology products and solutions aid various clientele worldwide.
       Zenith offers consulting, solutioning & Process Re-engineering services.
        We help you to stay ahead of the race through our products and solutions. "
    /> -->

    <Design2
      imageNam1="mission.png"
      imageNam2="history.png"
      imageNam3="audience.png"
      :blocktitle1="blocktitle1"
      :blockdesc1="blockdesc1"
      :blocktitle2="blocktitle2"
      :blockdesc2="blockdesc2"
      :blocktitle3="blocktitle3"
      :blockdesc3="blockdesc3"
    />

    <!-- <Design3 /> -->
    <!-- <Design11 /> -->
    <!-- <Design11 /> -->
    <Design4 />
 <!-- <Design6 /> -->
      <Design7 />
      <!-- <Design29 /> -->
   <!-- <Design30 /> -->
    <!-- <Design31 /> -->
    <!-- <Design28 /> -->
    <Design11 />
    <Design15 />
    <Contactus/>
    <Design10 /> 
  </div>
</template>

<script>
// import Bannerfull from "../components/Bannerfull.vue";
// import Bannerfullvideo from "../components/Bannerfullvideo.vue";

import Header from "../components/Header.vue";
import Banner from "../components/Banner.vue";
// import Banner1 from "../components/Banner1.vue";
import Design15 from "../components/Design15.vue";
import Design2 from "../components/Design2.vue";
// import Design3 from "../components/Design3.vue";
import Design4 from "../components/Design4.vue";
// import Design6 from "../components/Design6.vue";
import Design7 from "../components/Design7.vue";
// import Design29 from "../components/Design29.vue";
// import Design30 from "../components/Design30.vue";
// import Design31 from "../components/Design31.vue";
// import Design28 from "../components/Design28.vue";
import Contactus from "../view/Contactus.vue";
import Design10 from "../components/Design10.vue";
import Design11 from "../components/Design11.vue";
export default {
  name: "Home",
  data() {
    return {
      isLoading: true,
      header1: "Extensive Title Production Services",
      desc1:
        "Detailed title production for informed decision-making while issuing Title policy, Re-sale, Re-finance, or Foreclosure Auction and much more.",
      button1: "Learn More",
      header2: "End-to-end Escrow & Mortgage Services",
      desc2:
        "Ease of property transaction at its best with an end-to-end reviewed, recorded, & customized tracking of escrow and mortgage activities.",
      button2: "Learn More",
      header3: "Verified Appraisal Services",
      desc3:
        "Verified property appraisal ensuring precise valuation based on the key market indicators from credible sources and cross-checked by experts.",
      button3: "Ask for a Quote",
      blocktitle1: "Mission",
      blockdesc1:
        "We are a team composed of industry veterans dedicated to providing progressive solutions in title, settlement, mortgage, and data services. How do we do it? We recruit top talent for best quality service, leverage the intersection of automation and human verification, and create bespoke solutions for each and every customer. We’re Zenith: Settlement, Simplified.",
      blocktitle2: "History",
      blockdesc2:
        "Our humble beginnings can be traced back to 2014 when one of our sister companies, Stellar Innovations, began providing software and IT solutions to title insurance providers. Over the subsequent years, the organization expanded to heightened endeavors, including plant indexing, mortgage solutions, and escrow solutions. Zenith is the most recent and ambitious expansion, bringing a cohesive and targeted strategy to market.",
      blocktitle3: "Audience",
      blockdesc3:
        "While our core market is faithfully supporting title, settlement, and mortgage professionals, the secret to our success is owed to always identifying new markets and client bases to assist. To that end, if you’re looking for help, we’re confident that we can find a solution for your business.",
    };
  },

  components: {
    // Bannerfullvideo,
    // Bannerfull,
    Header,
    Banner,
    // Banner1,
    // Design1,
    Design2,
    // Design3,
    Design4,
    // Design6,
    Design7,
      Design11,
    // Design29, 
    // Design30,  
    // Design31, 
    // Design28,
    Design15,
    Contactus,
    Design10,
    // Design11,
    // Design12,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
.bannercarousal .v-responsive__content {
  /* height: 600px !important; */
  padding: 0px !important;
}

.v-window__next,
.v-window__prev {
  display: none;
}

.v-input__slot {
  box-shadow: none !important;
}

.v-label {
  color: #184825 !important;
}
/* .v-toolbar__content {
  padding: 4px 0px;
} */
@media only screen and (max-width: 960px) {
  .v-window__next,
  .v-window__prev {
    top: calc(70% - 20px);
  }
  .v-carousel__controls {
    display: none !important;
  }
  .tablemenu .v-toolbar__content {
    /* padding: 0px !important; */
    float: none !important;
  }
}
</style>
