<template>
  <v-app>
    <!-- <Header /> -->

    <v-main>
      <router-view></router-view>
    </v-main>

  </v-app>
</template>

<script>
// import Header from "./components/Header.vue";

export default {
  name: "App",
  components: {
    // Header,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");
#app {
  font-family: "Merriweather Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: red; */
  text-align: center;
  color: #184825;
  width: 100rem;
  margin: auto;
  max-width: 100%;
}

.v-toolbar__content {
  float: right;
  font-weight: bold;
}

.v-btn:hover {
  caret-color: darkslategray;
}
.vs__dropdown-toggle{
  background-color: rgb(237, 247, 255) !important;
  border:none !important;

}
.bar-menu-items {
  border-radius: 5px !important;
}

.bar-button.active {
  color: #ffffff !important;
  background-color: #184825 !important;
}

.bar-button:hover {
  color: #ffffff !important;
  cursor: pointer;
  background-color: #184825 !important;
}
.bar-menu-item,
.bar-menu {
  font-family: "Poppins", sans-serif;
}
.bar-menu-item:hover {
  color: #000 !important;
  cursor: pointer;
  background-color: #e5f4f7 !important;
}

.bar .bar-button > .chevron {
  padding-top: 20px;
}

.bar-button > span.label {
  font-family: "Poppins", sans-serif;
  font-size: 16px !important;
  padding-top: 20px !important;
}

.bottom-right {
  text-align: right;
}

.v-window__container {
  margin: auto;
}

.theme--light.v-sheet--outlined {
  border: none !important;
}
.v-btn--icon.v-size--default {
  height: 25px !important;
  width: 25px !important;
}

.theme--dark.v-btn.v-btn--icon {
  /* color: unset; */
}

.color_grey {
  color: gray;
}

.v-btn-toggle--group {
  background-color: unset;
}

.v-tabs {
}

@media only screen and (min-width: 960px) {
  .headermenu .v-btn__content {
    padding-top: 30px !important;
  }
}

@media only screen and (max-width: 960px) {
  .v-responsive__content {
    padding: 0px 10px;
  }

  .v-carousel__item{
  height:auto !important;
}

.v-carousel{
  height:auto !important;
}


}
</style>
