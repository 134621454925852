<template>
  <div id='FAQ' class="sectioncontent">
    <v-row>
      <v-col lg="4" sm="12" md="12">
        <p class="contenthead">Frequently Asked Questions</p>
        <p class="contentdesc">
          We are a technology-focused company, however 
          we firmly believe not every problem in our 
          industry is solved with process automation.
           At Zenith, we methodically infuse industry 
           knowledge and procedural diligence when creating tailored solutions. 
          At Zenith, we’re here to make your life easier. 
        </p>

        <ul class="contentlist">
          <li><b>Experienced Veterans At All Levels</b></li>
          <li><b>Integration of Technology & Verification </b></li>
          <li><b>50 State Solutions </b></li>
          <li><b>24/7 Operations </b></li>
          <li><b>Industry-Leading Cost Savings </b></li>
          <li><b>Proactive Quality Controls</b></li>
        </ul>
        <!-- <div class="text-left">
          <v-btn
            @click="returntocontact"
            class="cardtextbutton"
            height="35px"
            rounded
            dark
          >
            Contact Us
          </v-btn>
        </div> -->
      </v-col>



      <v-col lg="8" sm="12" md="12">
         <v-carousel
      class=" hidden-md-and-up"
      :show-arrows="false"
      cycle
      hide-delimiter-background
    >
      <v-carousel-item>
         <v-card :elevation="hover ? 6 : 0" class="alignleft" flat>
                <img class="imagelogo" src="../image/global_presence.svg" />
                <h3 class="headercontent">What does Zenith do?</h3>
                <p class="contentdesc_block">
                  At Zenith, we always start with the ‘why’ before the ‘what’. To that end, our suite of services is always expanding as we discover new and exciting ways to help others in various industries. That being said, our primary client base is made up of title, settlement,  and mortgage professionals all across the country.
                </p>
                <!-- <v-btn class="smallbutton" x-small color="#21A049" text rounded>
                  Learn more
                </v-btn> -->
              </v-card>
        </v-carousel-item>
             <v-carousel-item> <v-card :elevation="hover ? 6 : 0" class="alignleft" flat>
                <img class="imagelogo" src="../image/skilled_resource.svg" />
                <h3 class="headercontent">Why is it beneficial to partner with Zenith?</h3>
                <p class="contentdesc_block">
                  You know your business inside and out, and know what’s best for your team, your clients, and your bottom line. Our job is to provide you the best tools at the best possible price, so you are able to do exactly what’s made you successful. At Zenith, our mission is remove the roadblocks that distract from your success,
                  and our tailored approach to that goal guarantees simplicity and success for your business.
                </p>
                <!-- <v-btn class="smallbutton" x-small color="#21A049" text rounded>
                  Learn more
                </v-btn> -->
              </v-card></v-carousel-item>
                   <v-carousel-item> <v-card :elevation="hover ? 6 : 0" class="alignleft1" flat
                ><img class="imagelogo" src="../image/iso_certified.svg" />
                <h3 class="headercontent">Is there a way I can try Zenith out before signing up?</h3>
                <p class="contentdesc_block">
                Absolutely. We know that finding the right partner for your business can be a strenuous task, and we prefer that our work speaks for itself. Before you sign anything, we will be happy to run test orders for you to check quality, turn times, or any other details you require.
                </p>

                <!-- <v-btn class="smallbutton" x-small color="#21A049" text rounded>
                  Learn more
                </v-btn> -->
              </v-card></v-carousel-item>
                         <v-carousel-item>  <v-card class="alignleft1" :elevation="hover ? 6 : 0" flat
                ><img class="imagelogo" src="../image/secured.svg" />
                <h3 class="headercontent">Do you work with my title/ escrow software?</h3>
                <p class="contentdesc_block">
                  We have existing partnerships and integrations with many of the industry’s largest software providers. Additionally, our teams are exceptionally trained and familiar with all major systems, so whether you want integrated data or backend processing support, we have you covered.
                </p>

                <!-- <v-btn class="smallbutton" x-small color="#21A049" text rounded>
                  Learn more
                </v-btn> -->
              </v-card></v-carousel-item>
                       
        </v-carousel>
        <v-row class="hidden-sm-and-down">
          <v-col lg="6" sm="6" md="12">
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card :elevation="hover ? 6 : 0" class="alignleft" flat>
                <img class="imagelogo" src="../image/global_presence.svg" />
                <h3 class="headercontent">What does Zenith do?</h3>
                <p class="contentdesc_block">
                  At Zenith, we always start with the ‘why’ before the ‘what’. To that end, our suite of services is always expanding as we discover new and exciting ways to help others in various industries. That being said, our primary client base is made up of title, settlement,  and mortgage professionals all across the country.
                </p>
                <!-- <v-btn class="smallbutton" x-small color="#21A049" text rounded>
                  Learn more
                </v-btn> -->
              </v-card>
            </v-hover>
          </v-col>
          <v-col lg="6" sm="6" md="12">
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card :elevation="hover ? 6 : 0" class="alignleft" flat>
                <img class="imagelogo" src="../image/skilled_resource.svg" />
                <h3 class="headercontent">Why is it beneficial to partner with Zenith?</h3>
                <p class="contentdesc_block">
                  You know your business inside and out, and know what’s best for your team, your clients, and your bottom line. Our job is to provide you the best tools at the best possible price, so you are able to do exactly what’s made you successful. At Zenith, our mission is remove the roadblocks that distract from your success,
                  and our tailored approach to that goal guarantees simplicity and success for your business.
                </p>
                <!-- <v-btn class="smallbutton" x-small color="#21A049" text rounded>
                  Learn more
                </v-btn> -->
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
        <v-row  class="hidden-sm-and-down">
          <v-col lg="6" sm="6" md="12">
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card :elevation="hover ? 6 : 0" class="alignleft1" flat
                ><img class="imagelogo" src="../image/iso_certified.svg" />
                <h3 class="headercontent">Is there a way I can try Zenith out before signing up?</h3>
                <p class="contentdesc_block">
                Absolutely. We know that finding the right partner for your business can be a strenuous task, and we prefer that our work speaks for itself. Before you sign anything, we will be happy to run test orders for you to check quality, turn times, or any other details you require.
                </p>

                <!-- <v-btn class="smallbutton" x-small color="#21A049" text rounded>
                  Learn more
                </v-btn> -->
              </v-card>
            </v-hover>
          </v-col>
          <v-col lg="6" sm="6" md="12" >
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card class="alignleft1" :elevation="hover ? 6 : 0" flat
                ><img class="imagelogo" src="../image/secured.svg" />
                <h3 class="headercontent">Do you work with my title/ escrow software?</h3>
                <p class="contentdesc_block">
                  We have existing partnerships and integrations with many of the industry’s largest software providers. Additionally, our teams are exceptionally trained and familiar with all major systems, so whether you want integrated data or backend processing support, we have you covered.
                </p>

                <!-- <v-btn class="smallbutton" x-small color="#21A049" text rounded>
                  Learn more
                </v-btn> -->
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Design7",
  props: {},
  data() {
    return {};
  },
  methods: {
    returntocontact() {
      this.$store.dispatch("changeHeader", "Contactus");


 var element = document.getElementById('Contactus');
      element.scrollIntoView({behavior: "smooth"});



    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-card.on-hover.theme--dark {
  background-color: rgba(#fff, 0.8);
  opacity: 0.38;
}

.v-card.on-hover.theme--dark.v-card__text {
  color: #000;
}

.sectioncontent {
  background-color: white;
  text-align: left;
  /* margin-top: 25px;
  margin-bottom: 25px; */
  padding: 70px 8em 80px;
  /* padding-top: 6em; */
}

.cardtextbutton {
  font-size: 0.7em !important;
  font-weight: bold;
  margin-top: 15px;
  background: #184825 0% 0% no-repeat padding-box !important;
  border-radius: 34px;
  opacity: 1;
  margin-left: 5px;
}

.v-card{
  min-height: 255px;
}

.headercontent {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
  letter-spacing: 0px;
  color: #133063;
  opacity: 1;
  padding-top: 20px;
}

.imagelogo {
  height: 35px;
  width: 35px;
}
.testiimagelogo {
  /* height: 25%; */
  width: 100%;
}
.smallbutton {
  /* display: none; */
  padding-top: 10px !important;
  font-size: 12px;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
  position: absolute;
  bottom: 15px;
}

.alignleft {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: left;
  padding: 20px;
  height: 24em;
  max-height: 28em;
  width: 420px;
  margin: auto;
  border: 1px solid #dddddd;
  border-radius: 10px;
  margin-bottom: 36px;
}

.alignleft1 {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: left;
  padding: 20px;
  height: 20em;
  max-height: 24em;
  width: 420px;
  margin: auto;
  border: 1px solid #dddddd;
  border-radius: 10px;
  margin-bottom: 36px;
}

.imagewidth {
  width: 706px;
  height: 406px;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.imageleft {
  margin: auto;
}

.contenthead {
  text-align: left;
  font-size: 28px;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: bold;
  letter-spacing: 0px;
  color: #133063;
  opacity: 1;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-top: 30px;
}

.contentdesc {
  text-align: justify;
  margin-bottom: 30px;
  font-size: 16px;
  font-family: "Nunito", sans-serif;
  letter-spacing: 0.24px;
  color: #87878A;
}

.contentdesc_block {
  text-align: left;
  margin-top: 10px;
  font-size: 16px;
  font-family: "Nunito", sans-serif;
  letter-spacing: 0.24px;
  color: #87878A;
}

.contentlist {
  /* padding-top: 14px; */
  /* margin-left: 10px; */
  text-align: left;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  color: #151515;
  opacity: 1;
}

ul {
  list-style-type: none;
}

ul li:before {
    content: "";
  display: inline-block;
  height: 48px;
  width: 48px;
  background-image: url("../image/tickbullet.svg");
  background-size: contain;
  background-repeat: no-repeat;
  /* margin-left: -35px; */
}

li {
  /* padding-bottom: 10px; */
}

li b {
  position: absolute !important;
  padding-top: 10px !important;
}

@media only screen and (max-width: 960px) {
  .sectioncontent {
    padding: 15px 30px;
  }

  .cardtextbutton {
    width: 100%;
  }

  .contentlist{
    padding-left: 0px;
  }

  .alignleft, .alignleft1 {
    height: 450px;
    max-height: 450px;
  }
}

@media only screen and (max-width: 900px),
  (height: 1024px) and (width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape),
  (width: 1024px) and (height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
}
</style>
