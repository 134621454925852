<template>
  <!-- <v-card flat class="headersection d-flex justify-end"> -->
    
  <v-toolbar
    absolute
    class="headerblock"
    :class="{ change_color: scrollPosition > 150 }"
    flat
    dark
  >
    <img
      class="d-block d-flex headerimage"
      :src="require('../image/logo.png')"
      @click="routeHome"
    />
    <v-menu class="mobilemenu hidden-lg-and-up">
      <template v-slot:activator="{ on }">
        <v-app-bar-nav-icon
          class="hidden-lg-and-up"
          color="black"
          v-on="on"
        ></v-app-bar-nav-icon>
      </template>
      <!-- <v-list>
        <v-list-tile>
          <v-list-tile-content>
            <Headerbutton
              :label="{ text: 'Home', value: 'Home' }"
              :flags="true"
            />
          </v-list-tile-content>
          
           <v-list-tile-content>
            <Headerbutton
              :label="{ text: 'Products', value: 'Products' }"
              :flags="false"
            />
          </v-list-tile-content>
           <v-list-tile-content>
            <Headerbutton 
              :label="{ text: 'Our Services', value: 'OurServices' }"
              :flags="false"
            />
          </v-list-tile-content>
          <v-list-tile-content>
            <Headerbutton
              :label="{ text: 'About Us', value: 'AboutUs' }"
              :flags="false"
            />
          </v-list-tile-content>
        </v-list-tile>
         <v-list-tile-content>
            <Headerbutton
              :label="{ text: 'Contact Us', value: 'ContactUs' }"
              :flags="false"
            />
          </v-list-tile-content>
      </v-list> -->
    </v-menu>

    <!-- <v-toolbar-items class=" hidden-md-and-down">
      <Headerbutton :label="{ text: 'Home', value: 'Home' }" :flags="true" />
    </v-toolbar-items> <v-toolbar-items href="#keyproducts" class="  hidden-md-and-down">
      <Headerbutton
        :label="{ text: 'Products', value: 'Products' }"
        :flags="false"
      />
    </v-toolbar-items>

     <v-toolbar-items href="#ourservices" class="  hidden-md-and-down">
      <Headerbutton
        :label="{ text: 'Our Services', value: 'OurServices' }"
        :flags="false"
      />
    </v-toolbar-items>
    
 
    <v-toolbar-items class=" hidden-md-and-down">
      <Headerbutton
        :label="{ text: 'About Us', value: 'AboutUs' }"
        :flags="false"
      />
    </v-toolbar-items>
        <v-toolbar-items class=" hidden-md-and-down">
      <Headerbutton
        :label="{ text: 'Contact Us', value: 'ContactUs' }"
        :flags="false"
      />
    </v-toolbar-items> -->
    <v-spacer></v-spacer>
    <!-- <v-btn icon>
      <v-icon>mdi-magnify</v-icon>
    </v-btn> -->
  </v-toolbar>

</template>

<script>
// import Headerbutton from "./Headerbutton";

export default {
  name: "Header",
  components: {
    // Headerbutton,
  },

  props: {},
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    routeHome() {
      this.$store.dispatch("changeHeader", "Home");
      return this.$router.push(`./`);
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    console.log(this.scrollPosition);
    console.log(window.scrollY);
  },
  data() {
    return {
      scrollPosition: null,
      menu: [
        { icon: "home", title: "Link A" },
        { icon: "info", title: "Link B" },
        { icon: "warning", title: "Link C" },
      ],
    };
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.headerblock {
  padding: 0px 8em;
  width: 100%;
  position: fixed !important;
  background-color: #BEBEBE1D !important;
}

.topmenu .bar .bar-button.open:hover {
  color: #ffff !important;
  font-family: "Poppins", sans-serif;
  background: #184825 0% 0% no-repeat padding-box !important;
  cursor: pointer;
  border-radius: 0px 0px 5px 5px;
  opacity: 1;
}
.headerimage {
  position: fixed !important;
  float: left;
  z-index: 9999;
  /* position: absolute; */
  /* top: 0px !important; */
  left: 2px !important;
   /* width: 200px; */
  height: 53px !important;
  opacity: 1;
}

.headerimage:hover {
  cursor: pointer;
}

@media only screen and (max-width: 960px) {
  .headerimage {
    /* left: 20px !important; */
    /* width: 150px; */
    height: 35px;
  }
}

.v-menu__content {
  top: 60px !important;
  left: unset !important;
  right: 10px;
}

@media only screen and (max-width: 1280px) {
  .headerimage {
    /* left: 100px !important; */
    height:46px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .headerbutton {
    width: 100%;
  }

  .v-menu__content {
    width: 250px;
  }

  .bar-button {
    width: 80%;
  }
  .headerblock {
    width: 100% !important;
    padding: 0px !important;
    /* padding-right: 10px; */
  }
}

.change_color {
  background-color: white !important;
  box-shadow: 0 5px 3px -1px rgb(0 0 0 / 10%) !important;
}

@media only screen and (min-width: 960px) {

  .headerblock {
    width: 100% !important;
    /* padding: 0px !important; */
    /* padding-right: 10px; */
  }

   .headerbutton {
    width: 100%;
  }

  .headerbutton .v-btn__content {
    padding-top: 20px !important;
  }
}
</style>
